//
// custom.scss
//

.huge {
	color: #008acd;
	font-size: 2.5em;
}

.nav-connect {
	display: flex !important;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	white-space: initial;
	text-align: center !important;
	position: relative;
	background-color: #464f5b;
	border: 1px solid #404954;
	border-width: 0 1px;
	min-height: 70px;
}

@media (max-width: 991px) {
	.nav-connect {
		max-width: 100px;
	}
}

@media (min-width: 991px) {
	.nav-connect {
		padding-left: 24px !important;
		padding-right: 24px !important;
	}
}

.account-user-avatar {
	width: 32px;
}

#blockies {
	border-radius: 50%;
	overflow: hidden;
}

.square {
	display: block;
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 100%;
}

.abs,
.square canvas {
	display: block;
	position: absolute;
	top: 0;
	width: 100%;
	left: 0;
	height: 100%;
}

.web3modal-modal-card,
.web3modal-provider-container {
	background: #181828 !important;
}

.web3modal-provider-container:hover {
	background: #434357 !important;
}

#WEB3_CONNECT_MODAL_ID {
	z-index: 99999999;
	position: absolute;
}

.irs {
	pointer-events: none !important;
}

.crypto-icon {
	width: 20px;
	height: 20px;
	object-fit: contain;
}
