//
// Cabin fonts
//

@font-face {
  font-family: 'Cabin';
  src: url('../fonts/Cabin-Regular.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Cabin';
  src: url('../fonts/Cabin-Italic.ttf');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Cabin';
  src: url('../fonts/Cabin-Medium.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Cabin';
  src: url('../fonts/Cabin-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Cabin';
  src: url('../fonts/Cabin-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Cabin';
  src: url('../fonts/Cabin-SemiboldItalic.ttf');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'Cabin';
  src: url('../fonts/Cabin-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Cabin';
  src: url('../fonts/Cabin-Bold.ttf');
  font-weight: 700;
  font-style: italic;
}